import { Component, EventEmitter, forwardRef, HostBinding, Input, Output, ContentChild, TemplateRef, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DayInfo } from '../../models/day-info';
import { DAY_NAMES } from '../../models/day-names';
import { MonthView } from '../../models/views/moth-view';
import { DayTemplateDirective } from '../../directives/day-template.directive';
import { DayOfWeekCaptionTemplateDirective } from '../../directives/day-of-week-caption-template.directive';
import { MonthCaptionTemplateDirective } from '../../directives/month-caption-template.directive';
import { DayOfWeek } from '../../models/day-of-week';
import { defaultDayOfWeekCaptionFormatterFactory } from '../../models/formatters/day-of-week-caption-formatter';
import { GrowMode } from '../../models/grow-mode';

/**
 * Month calendar provider.
 */
export const MONTH_CALENDAR_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => MonthCalendarComponent),
  multi: true
};

/**
 * Control that represents a calendar.
 */
@Component({
  selector: 'sc-month-calendar',
  template: `<!-- Month -->
<div (click)="onMonthClick()"
     [ngClass]="['column', grow.mode == 'stretch' ? 'stretch-vertically' : '', getClassForMonth()]">

  <!-- Month caption -->
  <div class="row">

    <!-- Month caption cell -->
    <div class="w-100"
         [class.label]="grow.mode != 'mixed'"
         [class.ratio-height]="grow.mode == 'proportional'"
         [class.stretch-horizontally]="grow.mode == 'stretch' || grow.mode == 'mixed'">

      <!-- Month caption cell content -->
      <div [class.label__content]="grow.mode != 'mixed'">

        <ng-container *ngTemplateOutlet="monthTemplate ? monthTemplate : defaultMonthTemplate; context: { $implicit: value }"></ng-container>

      </div>
    </div>
  </div>

  <!-- Week captions -->
  <div class="row">

    <!-- Week captions cell -->
    <div [class.label]="grow.mode != 'mixed'"
         [class.ratio-square]="grow.mode == 'proportional'"
         [class.stretch-horizontally]="grow.mode == 'stretch' || grow.mode == 'mixed'"
         *ngFor="let dayOfWeekCaption of daysOfWeekCaptions; let i = index">

      <!-- Week caption cell content -->
      <div [class.label__content]="grow.mode != 'mixed'">

        <ng-container *ngTemplateOutlet="dayOfWeekTemplate ? dayOfWeekTemplate : defaultDayOfWeekTemplate; context: { $implicit: dayOfWeekCaption, dayOfWeekIndex: i }"></ng-container>

      </div>
    </div>
  </div>

  <!-- Days -->
  <div class="row"
       *ngFor="let week of view">

    <!-- Day cell -->
    <div (click)="onDayClick(day)"
         class="ratio-square label"
         [class.ratio-square]="grow.mode == 'proportional' || grow.mode == 'mixed'"
         [class.stretch-horizontally]="grow.mode == 'stretch'"
         *ngFor="let day of week">

      <!-- Day content -->
      <div class="label__content">

        <ng-container *ngTemplateOutlet="dayTemplate ? dayTemplate : defaultDayTemplate; context: { $implicit: day }"></ng-container>

      </div>

    </div>

  </div>

</div>



<!-- Templates -->

<!-- Day template -->
<ng-template #defaultDayTemplate
             let-day>
  <div class="flex-expanded-container">
    <div [ngClass]="['flex-expand', getClassForDay(day)]">{{ getFormattedDay(day) }}</div>
  </div>
</ng-template>

<!-- Day of week cells template -->
<ng-template #defaultDayOfWeekTemplate
             let-dayOfWeek
             let-dayOfWeekIndex="dayOfWeekIndex">
  <div [class.flex-expanded-container]="grow.mode != 'mixed'">
    <div [ngClass]="['flex-expand', 'flex-centered', dayOfWeekCaptionClass]">
      {{ dayOfWeek }}
    </div>
  </div>
</ng-template>

<!-- Month cell template -->
<ng-template #defaultMonthTemplate
             let-date>
  <div [class.flex-expanded-container]="grow.mode != 'mixed'">
    <div [ngClass]="['flex-expand', 'flex-centered', monthCaptionClass]">
      {{ monthCaption }}
    </div>
  </div>
</ng-template>
`,
  styles: [`.column{display:flex;flex-flow:column;flex:1}.row{display:flex;flex:1}.label{position:relative}.label__content{position:absolute;top:0;left:0;bottom:0;right:0}.flex-expanded-container{display:flex;position:absolute;width:100%;height:100%}.flex-expand{flex:1}.flex-centered{display:flex;align-items:center;justify-content:center}.ratio-height{padding-bottom:14.28571%}.ratio-square{padding-bottom:14.28571%;width:14.28571%}.stretch-vertically{height:100%}.stretch-horizontally{width:14.28571%}.fixed-height{display:flex;flex:1}.w-100{width:100%}.sc-month{font-size:2rem}.sc-month--disabled{opacity:.25}.sc-month__caption,.sc-month__week-caption{border:1px solid #000;font-weight:700}.sc-month__day{border:1px solid #000;cursor:pointer}.sc-month__day--disabled{cursor:auto}.sc-month__day--today{background:pink}.sc-month__day--selected{background:#6495ed}`],
  providers: [MONTH_CALENDAR_VALUE_ACCESSOR]
})
export class MonthCalendarComponent implements ControlValueAccessor, OnInit {
  @ContentChild(DayTemplateDirective, { read: TemplateRef }) dayTemplate;
  @ContentChild(DayOfWeekCaptionTemplateDirective, { read: TemplateRef }) dayOfWeekTemplate;
  @ContentChild(MonthCaptionTemplateDirective, { read: TemplateRef }) monthTemplate;

  /**
   * Event raised when the user selects a date.
   */
  @Output('change') change = new EventEmitter<Date>();

  /**
   * Event raised when the user clicks the calendar.
   */
  @Output('monthClick') monthClick = new EventEmitter<MonthCalendarComponent>();

  /**
   * Sets if the control should be in a
   * disabled state.
   */
  @Input() disabled = false;

  /**
   * Date to show.
   */
  private _value = new Date();

  get value(): Date {
    return this._value;
  }

  @Input() set value(date: Date) {
    this.writeValue(date);
  }

  /**
   * Specifies how a day cell should grow.
   */
  private _grow: GrowMode = { mode: 'stretch' };

  @Input() set grow (mode: GrowMode) {
    if (mode) {
      this._grow = mode;
    } else {
      this._grow = { mode: 'stretch' };
    }
  }

  get grow(): GrowMode {
    return this._grow;
  }

  private defaultFirstDayOfWeek = DayOfWeek.Sunday;

  private defaultDayOfWeekCaptionFormatter = defaultDayOfWeekCaptionFormatterFactory(this.defaultFirstDayOfWeek);

  /**
   * First day of the week.
   */
  private _firstDayOfWeek: DayOfWeek = this.defaultFirstDayOfWeek;

  @Input() set firstDayOfWeek (dayOfWeek: DayOfWeek) {
    this._firstDayOfWeek = dayOfWeek;
    this.defaultDayOfWeekCaptionFormatter = defaultDayOfWeekCaptionFormatterFactory(this._firstDayOfWeek);
    this.refresh();
  }

  get firstDayOfWeek(): DayOfWeek {
    return this._firstDayOfWeek;
  }

  /**
   * Formatter for days.
   */
  private _dayFormatter: (day?: DayInfo) => string;

  @Input() set dayFormatter (formatter: (day?: DayInfo) => string) {
    this._dayFormatter = formatter;
    this.refresh();
  }

  get dayFormatter(): (day?: DayInfo) => string {
    return this._dayFormatter;
  }

  /**
   * Captions of the different days of the week.
   */
  daysOfWeekCaptions;

  /**
   * Formatter for the captions of the different
   * days of the week.
   */
  private _dayOfWeekCaptionFormatter: (dayOfWeek: DayOfWeek) => string;

  @Input() set dayOfWeekCaptionFormatter (formatter: (dayOfWeek: DayOfWeek) => string) {
    this._dayOfWeekCaptionFormatter = formatter;
    this.refresh();
  }

  get dayOfWeekCaptionFormatter(): (dayOfWeek: DayOfWeek) => string {
    return this._dayOfWeekCaptionFormatter;
  }

  /**
   * Caption of the month.
   */
  monthCaption;

  /**
   * Formatter for the month caption.
   */
  private _monthCaptionFormatter: (date: Date) => string;

  @Input() set monthCaptionFormatter (formatter: (date: Date) => string) {
    this._monthCaptionFormatter = formatter;
    this.refresh();
  }

  get monthCaptionFormatter(): (date: Date) => string {
    return this._monthCaptionFormatter;
  }

  /**
   * Retrieves a CSS class for the specified day.
   */
  @Input() customDayClass: (day: DayInfo) => string;

  /**
   * CSS class for the month.
   */
  @Input() monthClass = 'sc-month';

  /**
   * CSS class for the disabled state.
   */
  @Input() disabledClass = 'sc-month--disabled';

  /**
   * CSS class for the month caption.
   */
  @Input() monthCaptionClass = 'sc-month__caption';

  /**
   * CSS class for the day of the week captions.
   */
  @Input() dayOfWeekCaptionClass = 'sc-month__week-caption';

  /**
   * CSS class for the day captions.
   */
  @Input() dayCaptionClass = 'sc-month__day';

  /**
   * CSS class for the current day.
   */
  @Input() currentDayClass = 'sc-month__day--today';

  /**
   * CSS class for the day when the state is disabled.
   */
  @Input() disabledDayClass = 'sc-month__day--disabled';

  /**
   * CSS class for the selected day.
   */
  @Input() selectedDayClass = 'sc-month__day--selected';

  /**
   * View of the current month.
   */
  view;

  private defaultMonthCaptionFormatter = (date: Date) => date.toDateString();
  private defaultDayFormatter = (day?: DayInfo) => day ? day.day.toString() : '';
  private onChange = (date: Date) => { };
  private onTouched = () => { };

  /**
   * Initializes the component.
   */
  ngOnInit() {
    this.refresh();
  }

  writeValue(date: Date): void {
    if (date) {
      this._value = date;
      this.refresh();
      this.onChange(date);
    }
  }

  registerOnChange(fn: (date: Date) => {}): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /**
   * Refreshes the component.
   */
  private refresh(): void {
    this.refreshMonthCaption(this.value);
    this.refreshDayOfWeekCaptions();
    this.refreshView(this.value);
  }

  /**
   * Refreshes the month caption.
   * @param date Date.
   */
  private refreshMonthCaption(date: Date): void {
    if (this.monthCaptionFormatter) {
      this.monthCaption = this.monthCaptionFormatter(date);
    } else {
      this.monthCaption = this.defaultMonthCaptionFormatter(date);
    }
  }

  /**
   * Refreshes the day of week captions.
   */
  private refreshDayOfWeekCaptions(): void {
    const dayCaptions: string[] = [];

    const dayOfWeekFormatter = this.dayOfWeekCaptionFormatter ?
      this.dayOfWeekCaptionFormatter :
      this.defaultDayOfWeekCaptionFormatter;

    for (let i = 0; i < DAY_NAMES.length; i++) {
      dayCaptions.push(dayOfWeekFormatter(i));
    }

    this.daysOfWeekCaptions = dayCaptions;
  }

  /**
   * Refreshes the calendar view.
   * @param date Date.
   */
  private refreshView(date: Date): void {
    this.view = new MonthView(date).createView(false, this.firstDayOfWeek);
  }

  /**
   * Gets the CSS classes to apply to the month.
   */
  getClassForMonth(): string {
    let classesToApply = this.monthClass;

    if (this.disabled) {
      classesToApply = this.monthClass + ' ' + this.disabledClass;
    }

    return classesToApply;
  }

  /**
   * Gets the CSS class applicable to
   * the specified day.
   * @param day Day.
   */
  getClassForDay(day?: DayInfo): string {
    let dayClassToApply = '';

    if (day) {

      if (day.day === this.value.getDate()) {
        dayClassToApply = this.selectedDayClass;
      } else if (day.isToday) {
        dayClassToApply = this.currentDayClass;
      } else if (this.customDayClass) {
        const date = new Date(this.value.valueOf());
        date.setDate(day.day);
        dayClassToApply = this.customDayClass(day);
      }

      if (this.disabled) {
        dayClassToApply = dayClassToApply + ' ' + this.disabledDayClass;
      }

      return this.dayCaptionClass + ' ' + dayClassToApply;
    } else {
      return this.dayCaptionClass;
    }
  }

  /**
   * Gets a formatted string corresponding
   * to the specified day.
   * @param day Day to format.
   */
  getFormattedDay(day: DayInfo): string {
    if (this.dayFormatter) {
      return this.dayFormatter(day);
    } else {
      return this.defaultDayFormatter(day);
    }
  }

  /**
   * Controls the click event of a day cell.
   * @param dayInfo Info about the selected day.
   */
  onDayClick(dayInfo: DayInfo): void {
    if (!this.disabled && dayInfo) {
      const selectedDate = new Date(this.value.valueOf());
      selectedDate.setDate(dayInfo.day);

      this.value = new Date(selectedDate.valueOf());

      this.change.emit(selectedDate);
    }
  }

  /**
   * Controls the click event of the month.
   */
  onMonthClick(): void {
    if (!this.disabled) {
      this.monthClick.emit(this);
    }
  }
}
